import React from 'react';
import Maintenance from './components/maintenance'
import logo from './assets/logo.png';
import './app.css';

const App = () => (
  <div>
    <img alt="Zenospace logo" src={logo} />
    <Maintenance
      header="We will be back soon"
      msg="We are busy updating our site!"
    />
  </div>
);

export default App;
