import React from 'react';
import './maintenance.css';

const maintenance = (props) => {
  const { header, msg } = props;

  return (
    <div className="maintenance">
      <h1>{ header }</h1>
      <p>
        { msg }
      </p>
    </div>
  );
};

export default maintenance;
